import { Elements } from "@stripe/react-stripe-js";
import { css as emotionCss } from "emotion";
import { usePagination } from "hooks/usePagination";
import * as React from "react";
import { useEffect } from "react";
import { useNotifications } from "../../../../components/DiscoverNew/contexts/Notification/notifications";
import { ModalEdit } from "../../../../components/DiscoverNew/DiscoverNew/DiscoverFilters/ModalEdit";
import { injectStyleOverride } from "../../../../components/DiscoverNew/DiscoverNewWrapper";
import { Input } from "../../../../components/DiscoverNew/Form/Input/Input";
import { Button } from "../../../../components/DiscoverNew/UI/Button/Button";
import { Icon } from "../../../../components/DiscoverNew/UI/IconComponent/Icon";
import { Loading } from "../../../../components/DiscoverNew/UI/Loading/Loading";
import { withConfirm, WithConfirmProps } from "../../../../components/hoc/withConfirm";
import EditPlanModal from "../../../../components/modals/EditPlanModal/EditPlanModal";
import { InviteMemberModal } from "../../../../components/modals/InviteMemberModal/InviteMemberModal";
import { useAuthController } from "../../../../contexts/AuthProvider";
import { useUserData } from "../../../../contexts/userContext";
import { useQueryParams } from "../../../../hooks/useQueryParams";
import { initializeStripe } from "../../../../services/BillingService";
import { logger } from "../../../../services/Logger";
import {
  ORGANIZATION_ROLE_OPTIONS,
  OrganizationMemberInterface,
  OrganizationMemberInviteDTO,
  OrganizationMemberRole,
  OrganizationMemberStatus,
  organizationService,
} from "../../../../services/OrganizationService";
import { PermissionEnum, permissionService } from "../../../projects/PermissionService";

import css from "./BillingMembers.module.css";
import { BillingMembersTable } from "./BillingMembersTable/BillingMembersTable";

type Props = WithConfirmProps & {}

const stripePromise = initializeStripe();

const BillingMembersCore: React.FC<Props> = props => {
  useEffect(() => {
    return injectStyleOverride();
  }, []);

  const { fetchUserAndUsageData } = useUserData();
  const pagination = usePagination();
  const queryParams = useQueryParams({ query: '' });
  const [search, setSearch] = React.useState(queryParams.params.query);
  const authController = useAuthController();
  const user = authController.user as any;
  const notifications = useNotifications();

  const [isInviteMemberOpen, setIsInviteMemberOpen] = React.useState(false);
  const [isEditPlanOpen, setIsEditPlanOpen] = React.useState(false);
  const [members, setMembers] = React.useState<OrganizationMemberInterface[]>([]);
  const [memberToEdit, setMemberToEdit] = React.useState<OrganizationMemberInterface>();
  const [loading, setLoading] = React.useState(false);
  const [saveMemberError, setSaveMemberError] = React.useState('');

  function fetchData(params = queryParams.params) {
    setLoading(true);
    organizationService.fetchMembersAndInvitees({
      query: params.query,
      limit: pagination.limit,
      skip: pagination.skip
    })
      .then((members) => {
        setMembers(members);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }

  React.useEffect(() => {
    fetchData(queryParams.params);
  }, [queryParams.params.query, pagination.page, pagination.limit, pagination.skip]);

  const onSearchChange = (value: string) => {
    setSearch(value);
    queryParams.debouncedSetParams({ query: value });
  }

  const onInvited = () => {
    setIsInviteMemberOpen(false);
    fetchData();
    authController.fetchUser();
    fetchUserAndUsageData();
  };

  const onDeactivate = async (member: OrganizationMemberInterface) => {
    try {
      // todo preloader
      const isConfirmed = await props.confirm.open({
        title: "Make View Only",
        content: "Are you sure you want to make this member view only?",
        confirmButtonTitle: "Make View Only",
        className: emotionCss`
          .modal-content {
            width: 500px !important;
          }
        `
      })
      if (!isConfirmed) {
        return;
      }
      await organizationService.deactivateMember(member.id);
      notifications.showSuccess("Member has been made view only!");
      fetchData();
      authController.fetchUser();
      fetchUserAndUsageData();
    } catch (err) {
      console.error(err);
      notifications.showError("Couldn't make the member view only");
    }
  }

  const onActivate = async (member: OrganizationMemberInterface) => {
    try {
      // todo preloader
      const isConfirmed = await props.confirm.open({
        title: "Allow Credits Usage",
        content: "Are you sure you want to allow this member to use credits?",
        confirmButtonTitle: "Allow Credits Usage",
        className: emotionCss`
          .modal-content {
            width: 500px !important;
          }
        `
      })
      if (!isConfirmed) {
        return;
      }
      await organizationService.activateMember(member.id);
      notifications.showSuccess("Credit usage has been allowed!");
      fetchData();
      authController.fetchUser();
      fetchUserAndUsageData();
    } catch (err) {
      console.dir(err);
      if ((err as any).response.data.message === 'no available paid seats') {
        notifications.showError("You don't have available seats to allow credit usage for the member");
      } else {
        notifications.showError("Couldn't allow credit usage");
      }
    }
  }

  const onResendInvitation = async (member: OrganizationMemberInterface) => {
    try {
      await organizationService.resendMemberInvitation(member.id);
      notifications.showSuccess("The invitation has been sent!");
      fetchData();
    } catch (err) {
      console.error(err);
      notifications.showError("Couldn't send the invitation");
    }
  }

  const onSaveMember = async (data: { creditsLimit : string, role: { value: string, label: string } }) => {
    const creditsLimit = parseInt(data.creditsLimit);
    setSaveMemberError('');

    if (isNaN(creditsLimit)) {
      return { errors: { creditsLimit: "Credits limit must be a number" } };
    }
    if (creditsLimit < 0) {
      return { errors: { creditsLimit: "Credits limit can't be negative" } };
    }
    if (creditsLimit > (user.currentPlan.organizationCredits.count + user.currentPlan.singleUseCredits.count)) {
      return { errors: { creditsLimit: "Credits limit can't be higher than the plan credits limit" } };
    }
    const dto: { creditsLimit: number, role?: string } = {
      creditsLimit,
      role: data.role.value
    }
    if (isDisabledRoleSelection) {
      delete dto.role
    }
    try {
      await organizationService.updateMember(memberToEdit!.id, dto);
      notifications.showSuccess("Member has been updated!");
      fetchData();
      setMemberToEdit(undefined);
      authController.fetchUser();
      fetchUserAndUsageData();
    } catch (err) {
      logger.error('onSaveMember', err);
      // @ts-ignore
      if (err?.response?.data?.message === 'insufficient permissions') {
        setSaveMemberError('Insufficient Permissions');
      } else {
        setSaveMemberError("We couldn't save the changes, please contact with support");
      }
    }
  }

  const onDelete = async (member: OrganizationMemberInterface) => {
    try {
      // todo preloader
      const isConfirmed = await props.confirm.open({
        title: "Delete member",
        content: "Are you sure you want to delete this member?",
        confirmButtonTitle: "Delete",
        destructive: true,
      })
      if (!isConfirmed) {
        return;
      }
      if ([OrganizationMemberStatus.InvitationPending, OrganizationMemberStatus.InvitationExpired, OrganizationMemberStatus.InvitationDeclined].includes(member.status)) {
        await organizationService.deleteInvite(member.id);
      } else {
        await organizationService.deleteMember(member.id);
      }
      setMembers(members.filter(item => item.id !== member.id));
      notifications.showSuccess("Member has been deleted!");
      fetchData();
      authController.fetchUser();
      fetchUserAndUsageData();
    } catch (err) {
      console.error(err);
      notifications.showError("Couldn't delete the member");
    }
  }

  const isDisabledRoleSelection = memberToEdit?.id === user.id;

  return (
    <div className={css.holder}>
      <header className={css.header}>
        <div>
          <Input
            className={css.search} placeholder="Search by name"
            value={search}
            onChange={(e: any) => onSearchChange(e.target.value)}
          />
        </div>
        {permissionService.can(user, PermissionEnum.editOrganizationMembers) && (
          <Button leftIcon={<Icon name="plus" />} onClick={() => setIsInviteMemberOpen(true)}>Invite Member</Button>
        )}
      </header>
      {loading ? <Loading /> :
        <div className={css.content}>
          <BillingMembersTable
            onDeactivate={onDeactivate}
            onActivate={onActivate}
            onEdit={s => setMemberToEdit(s)}
            onResendInvitation={onResendInvitation}
            onDelete={onDelete}
            data={members}
          />
        </div>
      }
      {isInviteMemberOpen && (
        <InviteMemberModal onClose={() => setIsInviteMemberOpen(false)} onSubmitSuccess={onInvited} onPurchaseSeat={() => {
          setIsInviteMemberOpen(false);
          setIsEditPlanOpen(true);
        }} />
      )}
      {memberToEdit && (
        <ModalEdit
          onSubmit={onSaveMember}
          error={saveMemberError}
          onClose={() => setMemberToEdit(undefined)}
          title="Edit Member"
          submitButtonTitle="Save"
          config={[
            { name: "creditsLimit", label: "Credits Limit", type: 'text', defaultValue: memberToEdit.credits.limit },
            { name: "role", label: "Organization Role", type: 'select', disabled: isDisabledRoleSelection, defaultValue: ORGANIZATION_ROLE_OPTIONS.find(item => item.value === memberToEdit.role), options: ORGANIZATION_ROLE_OPTIONS.filter(item => item.value !== OrganizationMemberRole.Owner) },
          ]}
        />
      )}
      {isEditPlanOpen && (
        <Elements stripe={stripePromise} options={{ currency: 'usd' }}>
          <EditPlanModal onClose={() => setIsEditPlanOpen(false)} />
        </Elements>
      )}
    </div>
  );
};

export default withConfirm(BillingMembersCore);